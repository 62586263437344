'use client';

import { TMApi } from '@web/services/tmapi';
import { useIsRunningAsIOSApp } from '@web/utils/ios-app';
import { useIsRunningAsAndroidApp } from '@web/utils/android-app';
import { useCallback, useEffect } from 'react';

declare let globalThis: containerThis;
declare let androidTimeMachine: any;

interface containerThis {
  // iOS
  registerJSHandler: any;
  callContainer: (method: string, params: any) => void;
  // internal
  containerHandlerRegistered: boolean | undefined;
}

export default function useAppBridge() {
  const [isRunningAsIOSApp] = useIsRunningAsIOSApp();
  const [isRunningAsAndroidApp] = useIsRunningAsAndroidApp();

  // requestNotificationToken will ask for push notificiation permission if the app is running in a container context
  // registerNotificationToken will be called by the native app if it's been granted
  const requestNotificationToken = async () => {
    call('requestPushNotificationPermission', {});
  };

  const notifyAppLoaded = async () => {
    call('notifyAppLoaded', {});
  };

  const notifyUserAuthenticated = useCallback(async () => {
    call('notifyUserAuthenticated', {});
  }, []);

  const call = async (method: string, payload: any) => {
    console.debug(`calling ${method}`);
    if (isRunningAsIOSApp) {
      calliOS(method, payload);
    } else if (isRunningAsAndroidApp) {
      callAndroid(method, payload);
    } else {
      console.debug('no container detected, skipping call');
    }
  };

  const calliOS = (method: string, payload: any) => {
    // assumes callContainer was injected by the containing app
    if (!globalThis.callContainer) {
      console.error(
        'callContainer not found. Code is probably not embedded in a container.',
      );
      return;
    }
    globalThis.callContainer(method, payload);
  };

  const callAndroid = (method: string, payload: any) => {
    if (!androidTimeMachine) {
      console.error(
        'androidTimeMachine not found. Code is probably not embedded in a container.',
      );
    }
    // Android's JavaScript bridge doesn't like superflous arguments,
    // so let's call with no args if payload is null-ish
    if (payload && Object.keys(payload).length !== 0) {
      androidTimeMachine[method](payload);
    } else {
      androidTimeMachine[method]();
    }
  };

  const handleRegisterNotificationToken = async (payload: {
    deviceToken: string;
    system: string;
  }) => {
    const tmapi = new TMApi();
    await tmapi.registerNotificationToken(payload.deviceToken, payload.system);
  };

  useEffect(() => {
    if (
      !isRunningAsIOSApp &&
      (!isRunningAsAndroidApp || androidTimeMachine === undefined)
    ) {
      // neither running as iOS nor Android app
      return;
    }
    if (globalThis.containerHandlerRegistered) {
      return;
    }
    globalThis.containerHandlerRegistered = true;

    if (globalThis.registerJSHandler) {
      globalThis.registerJSHandler(
        'registerNotificationToken',
        handleRegisterNotificationToken,
      );
    }
  });

  return { requestNotificationToken, notifyAppLoaded, notifyUserAuthenticated };
}
