'use client';

import { useEffect, useState } from 'react';

function isRunningAsAndroidApp(): boolean {
  if (typeof androidTimeMachine === 'undefined') {
    return false;
  }
  return !!androidTimeMachine;
}

export function useIsRunningAsAndroidApp() {
  const [isRunningAsAndroidAppState, setIsRunningAsAndroidApp] =
    useState(false);

  useEffect(() => {
    const poller = setInterval(() => {
      const iraia = isRunningAsAndroidApp();
      setIsRunningAsAndroidApp(iraia);
      if (iraia) {
        // Once the app is running in the container, this is probably not changing later.
        clearInterval(poller);
      }
    }, 200);
    setTimeout(() => {
      clearInterval(poller);
    }, 5000); // abort after 5 seconds

    return () => clearInterval(poller);
  }, []);

  return [isRunningAsAndroidAppState];
}
